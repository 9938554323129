import React from 'react';
import dynamicCSS from './cssInJs/css';

const Card = ({title, description, theme, ...props}) => {
  return (
    <article>
        <div className={"card-layout " + dynamicCSS({card: theme})}>
            {title && <h4 className={"title-layout " + dynamicCSS({title: theme})}>{title}</h4>}
            {description && <p>{description}</p>}
            {props.children}
        </div>
    </article>
  )
};

export default Card;