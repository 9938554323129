import { css } from '@stitches/react';

const dynamicCSS = css({
    variants: {
        app: {
            neobrutalism: {
                background: 'linear-gradient(180deg, rgba(136,233,209,1) 0%, rgba(136,212,255,1) 50%, rgba(203,229,244,1) 75%)',
            },
            neuromorphism: {
                background: '#D1D8EC',
                color: '#2C394B'
            },
            glassmorphism: {
                position: 'absolute',
                zIndex: '100',
                color: 'white'
            }
        },
        header: {
            neobrutalism: {
                backgroundColor: '#0085FF',
                border: '.2rem solid black',
                borderBottom: '.3rem solid black',
                borderRight: '.3rem solid black',
                borderRadius: ' 0 .5rem .5rem',
                color: 'black',
                animation: 'backgroundColorChange 10s infinite',
            },
            neuromorphism: {
                borderRadius: '25px',
                boxShadow: '10px 10px 16px #b2b8c9, -10px -10px 16px #f0f8ff',
                color: '#2C394B',
                animation: 'backgroundPastelColorChange 10s infinite'
            },
            glassmorphism: {
                borderRadius: '10px',
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                backdropFilter: 'blur(6px)',
                color: 'white',
                animation: 'backgroundGlassColorChange 10s infinite',
            }
        },
        animated_text: {
            neobrutalism: {
                color: 'black',
                fontWeight: 'bold',
            },
            neuromorphism: {
                color: 'black',
                fontWeight: 'normal',
            },
            glassmorphism: {
                color: 'white',
                fontWeight: 'normal',
            }
        },
        profile_img: {
            neobrutalism: {
                border: '.3rem solid black'
            },
            neuromorphism: {
                border: 'none',
                boxShadow: 'inset 0.3rem 0.3rem 0.5rem #b2b8c9, inset -0.3rem -0.3rem 0.5rem #f0f8ff',
                padding: '1rem'
            },
            glassmorphism: {
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
                backdropFilter: 'blur(6px)',
                border: '1px solid rgba(255, 255, 255, 0.18)',
                padding: '1rem'
            },
        },
        profile_img_open: {
            false: {
                height: '40vh',
                transition: 'all 1s ease-out',
            }, 
            true: {
                height: '30vh',
                transition: 'all 1s ease-out',
            }
        },
        title: {
            neobrutalism: {
                fontSize: '2rem',
                padding: '.75rem',
                backgroundColor: '#21F4C2',
                border: '.2rem solid black',
                borderBottom: '.3rem solid black',
                borderRight: '.3rem solid black',
                borderRadius: '0 0 .5rem .5rem',
                fontWeight: 'bold',
                marginTop: '.5rem',
            },
            neuromorphism: {
                fontSize: '2rem',
                padding: '.5rem',
                display: 'block',
                margin: '0.5rem',
                fontWeight: 'normal',
            },
            glassmorphism: {
                fontSize: '1.5rem',
                padding: '.5rem',
                display: 'block',
                margin: '0.5rem',
            }
        },
        card: {
            neobrutalism: {
                backgroundColor: 'white',
                border: '0.2rem solid black',
                borderBottom: '.3rem solid black',
                borderRight: '.3rem solid black',
                borderRadius: '0rem 0rem .5rem .5rem',
                fontWeight: 'bold',
            },
            neuromorphism: {
                borderRadius: '50px',
                boxShadow: '12px 12px 24px #b2b8c9, -12px -12px 24px #f0f8ff',
                margin: '2.5rem 1.25rem',
            },
            glassmorphism: {
                
                borderRadius: '10px',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
                backdropFilter: 'blur(6px)',
                border: '1px solid rgba(255, 255, 255, 0.18)',
                color: 'white',
            }
        },
        button: {
            neobrutalism: {
                backgroundColor: 'white',
                border: '.25rem solid black',
                borderBottom: '.5rem solid black',
                borderRight: '.5rem solid black',
                borderRadius: '0rem 0rem .5rem .5rem',
                fontWeight: 'bold',
            },
            neuromorphism: {
                borderRadius: '50px',
                boxShadow: '10px 10px 20px #b2b8c9, -10px -10px 20px #f0f8ff',
                background: 'rgba( 1, 171, 15, 0.5)',
                border: 'none',
                margin: '0.5rem',
            },
            glassmorphism: {
                background: 'rgba( 1, 171, 15, 0.5)',
                borderRadius: '10px',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
                backdropFilter: 'blur(6px)',
                border: '1px solid rgba(1, 171, 15, 0.1)',
                color: 'white',
            }
        },
        modal: {
            neobrutalism: {
                backgroundColor: 'rgba(0,0,0,0.4)', /* Black w/ opacity */
            },
            neuromorphism: {
                backgroundColor: 'rgba(0,0,0,0.4)', /* Black w/ opacity */
            },
            glassmorphism: {
                backgroundColor: 'rgba(0,0,0,0.7)', /* Black w/ opacity */
            }
        },
        modal_content: {
            neobrutalism: {
                backgroundColor: 'white'
            },
            neuromorphism: {
                background: '#D1D8EC',
                borderRadius: '50px',
                // boxShadow: '12px 12px 24px #b2b8c9, -12px -12px 24px #f0f8ff',
            },
            glassmorphism: {
                background: 'rgba(255, 255, 255, 0.25)',
                borderRadius: '10px',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
                backdropFilter: 'blur(6px)',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                color: 'white',
                fontSize: '1rem'
            }
        },
        modal_header: {
            neobrutalism: {
                borderBottom: '.25rem solid gray',
                fontWeight: 'bold',
            },
            neuromorphism: {
            },
            glassmorphism: {
            }
        },
        modal_body: {
            neobrutalism: {
                borderBottom: '.25rem solid gray',
                fontWeight: 'bold',
            },
            neuromorphism: {
            },
            glassmorphism: {
            }
        },
        modal_footer: {
            neobrutalism: {
            },
            neuromorphism: {
            },
            glassmorphism: {
            }
        },
        inset_img: {
            neobrutalism: {
                border: '0.125rem solid black'
            },
            neuromorphism: {
                boxShadow: '6px 6px 12px #b2b8c9',
            },
            glassmorphism: {
            }
        },
        menu:{
            neobrutalism: {
                '& ul li a': {
                    color: '#2C394B'
                }
            },
            neuromorphism: {
                '& ul li a': {
                    color: '#2C394B'
                }
            },
            glassmorphism: {
                '& ul li a': {
                    color: 'white'
                }
            }
        },
        skill: {
            neobrutalism: {
                border: '.2rem solid black',
                backgroundColor: 'white',
                fontWeight: 'bold',
                padding: '0.5rem',
            },
            neuromorphism: {
                boxShadow: 'inset 0.3rem 0.3rem 0.5rem #b2b8c9, inset -0.3rem -0.3rem 0.5rem #f0f8ff',
                padding: '0.5rem',
            },
            glassmorphism: {
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
                backdropFilter: 'blur(6px)',
                border: '1px solid rgba(255, 255, 255, 0.18)',
                padding: '0.5rem',
                color: 'white'
            }
        },
        progressbar: {
            basic: {
                background: 'conic-gradient(transparent calc(var(--percentage) * 1%), #009A7C 0)',
                width: '7.25rem',
                height: '7.25rem'
            },
            intermediate: {
                background: 'conic-gradient(transparent calc(var(--percentage) * 1%), #009A7C 0)',
                width: '7.25rem',
                height: '7.25rem'
            },
            advanced: {
                background: 'conic-gradient(transparent calc(var(--percentage) * 1%), #009A7C 0)',
                width: '7.25rem',
                height: '7.25rem'
            }
        },
        circle: {
            basic: {
                width: '6.25rem',
                height: '6.25rem'
            },
            intermediate: {
                width: '6.25rem',
                height: '6.25rem'
            },
            advanced: {
                width: '6.25rem',
                height: '6.25rem'
            }
        },
        'inner-circle': {
            basic: {
                width: '6rem',
                height: '6rem'
            },
            intermediate: {
                width: '6rem',
                height: '6rem'
            },
            advanced: {
                width: '6rem',
                height: '6rem'
            }
        },
        isSkillOpen: {
            true: {
                transform:'scale(1.0)',
                opacity: '1',
                maxHeight: '1600px',
                left: '0%',
                top: '0%',
                transition: 'all 1s ease-out',
            }, 
            false: {
                transform: 'scale(0.5)',
                opacity: '0',
                maxHeight: '0%',
                left: '0%',
                right: '50%',
                top: '-10%',
                transition: 'all 1s ease-out',
                margin: '0',
            }
        },
        collapseIcon: {
            neobrutalism: {
                display: 'block',
                color: 'black',
                margin: '0 auto',
                opacity: '1'
            },
            neuromorphism: {
                display: 'block',
                color: 'gray',
                margin: '0 auto',
                opacity: '1'
            },
            glassmorphism: {
                display: 'block',
                color: 'white',
                margin: '0.5rem auto',
                opacity: '0.5'
            }
        },
        phrase: {
            neobrutalism: {
            },
            neuromorphism: {
            },
            glassmorphism: {
            }
        }
    }
});


export default dynamicCSS;