import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';

const LanguageSelector = ({langId, setLanguage}) => {
  const availableLanguages = [
    { id:"es", language:"Español" },
    { id:"en", language:"English" },
  ];
  
  return (
    <div className='languageSelector'>
        <FontAwesomeIcon icon={faLanguage} size="lg" className='text-gray'/>
        <ul className='languageList'>
          {
            availableLanguages.map((lan) => (
              <li key={lan.id}  onClick={() => setLanguage(lan.id)} className={lan.id === langId ? 'selectedLang' : 'availableLang'}>
                {lan.language}
              </li>
            ))
          }
        </ul>
    </div>
  )
}

export default LanguageSelector