import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import ErrorPage from './ErrorPage';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactGA from "react-ga4";

ReactGA.initialize("G-EQNRJW3RTP");
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />
  }
  // {
  //   path: 'aboutme',
  //   element: <App />
  // },
  // {
  //   path: 'audioblog',
  //   element: <div>Audioblog</div>
  // },
  // {
  //   path: 'books',
  //   element: <div>Books</div>
  // }
]);

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
