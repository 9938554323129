import React from 'react';
import dynamicCSS from './cssInJs/css';

const Header = props => {
  return (
    <header>
        <div className={"header-layout " + dynamicCSS({header: props.theme})}>
           {props.children}
        </div> 
    </header>
  )
};

export default Header;