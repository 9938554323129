import React from 'react';
import dynamicCSS from './cssInJs/css';
import ReactGA from "react-ga4";

const SocialLink = ({socialName, theme}) => {
  const mapSocialData = {
    instagram: {
      href: 'https://www.instagram.com/el_miguelowsky',
      img: 'instagram.png'
    },
    twitter: {
      href: 'https://twitter.com/El_Miguelowsky',
      img: 'twitter.png'
    },
    tiktok: {
      href: 'https://www.tiktok.com/@miguelowsky',
      img: 'tiktok.png'
    },
    linkedin:{
      href: 'https://www.linkedin.com/in/miguel-aguilar',
      img: 'linkedin.png'
    }
  };

  return (
    <a
        className="App-link"
        href={mapSocialData[socialName.toLowerCase()].href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => ReactGA.event({
          category: 'social_link',
          action: 'click',
          label: socialName.toLowerCase()
        })}
    >
        <img className={dynamicCSS({app_logo: theme})} src={mapSocialData[socialName.toLowerCase()].img} alt={socialName}/>
    </a>
  )
};

export default SocialLink;