import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import dynamicCSS from './cssInJs/css';
import { useState, useEffect } from 'react';
import { getTranslations } from './languages/utils';

const Presentation = (props) => {
  const [esSequence, setESSequence] = useState(null);
  const [enSequence, setENSequence] = useState(null);
  
  useEffect(() => {
    (async function(){
      // TODO: Fix this duplication
      const esResult = await getTranslations(`es-presentation-sequence`);
      const enResult = await getTranslations(`en-presentation-sequence`);
      setESSequence(esResult.presentationSequence);
      setENSequence(enResult.presentationSequence);
    })();
  }, []);

  return (
    <section>
        <div className={"animated-text " + dynamicCSS({animated_text: props.theme})}>
          {/* TypeAnimation component is a memoized component, need to fix it or created from scratch to make it updatable by state changes */}
          { props.langId === 'es' && esSequence && 
            <TypeAnimation
              // Same String at the start will only be typed once, initially
              sequence={esSequence}
              speed={35} // Custom Speed from 1-99 - Default Speed: 40
              deletionSpeed={55}
              wrapper="span" // Animation will be rendered as a <span>
              repeat={Infinity} // Repeat this Animation Sequence infinitely
            />
          }
          { props.langId === 'en' && enSequence && 
            <TypeAnimation
              // Same String at the start will only be typed once, initially
              sequence={enSequence}
              speed={35} // Custom Speed from 1-99 - Default Speed: 40
              deletionSpeed={55}
              wrapper="span" // Animation will be rendered as a <span>
              repeat={Infinity} // Repeat this Animation Sequence infinitely
            />
          }
        </div>
      </section>
  )
};

export default Presentation;