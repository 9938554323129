import React from 'react';
import dynamicCSS from './cssInJs/css';

const Modal = ({title, description, btnText, btnHandler, theme, ...props}) => {
  return (
    <div className={"modal " + dynamicCSS({modal: theme})}>
        <div className={"modal-content " + dynamicCSS({modal_content: theme})}>
            <div className={"modal-header " + dynamicCSS({modal_header: theme})}>
                {title}
            </div>
            <div className={"modal-body " + dynamicCSS({modal_body: theme})}>
                {description}
                {props.children}
            </div>
            <div className={"modal-footer " + dynamicCSS({modal_footer: theme})}>
                <button className={dynamicCSS({button: theme})} onClick={btnHandler}>
                    {btnText}
                </button>  
            </div>      
        </div>
    </div> 
  )
}

export default Modal;