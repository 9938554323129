import "animate.css/animate.min.css";
import "./css/main.css";
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrush, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import themes from './themes.json';
import ScrollAnimation from 'react-animate-on-scroll';
// import Menu from './Menu';
import Header from './Header';
import Presentation from './Presentation';
import Card from './Card';
import Modal from './Modal';
import SocialLink from './SocialLink';
import { getTextWithLink, getTranslations } from './languages/utils';
import LanguageSelector from './LanguageSelector';
import dynamicCSS from './cssInJs/css';
import Skill from './Skill';
import useWindowSize from './useWindowSize';
import ReactGA from "react-ga4";

function App() {
  
  const [isModalVisible, setModal] = useState(false);
  
  const [showThemeOptions, setShowThemeOptions] = useState(false);
  const [theme, setTheme] = useState(themes.GLASSMORPHISM);
  const [translations, setTranslations] = useState({});
  const userLang = navigator.language || navigator.userLanguage || 'en'; // default language 'en' 
  const [langId, setLanguage] = useState(userLang.split("-")[0]);
  const windowSize = useWindowSize();
  let isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? true : false;
  const [isSkillOpen, setSkillOpen] = useState(!isMobile);
  useEffect(() => {
    setModal(true);
  }, []);

  useEffect(() => {
    (async function(){
      const result = await getTranslations(langId);
      setTranslations(result);
    })();
  }, [setModal, langId, setTranslations]);

  useEffect(() => {
    // recalculate isMobile flag if window was resized
    isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? true : false;
  }, [windowSize])

  // Send custom google analytics event
  const sendGAEvent = (category, action, label) => {
    ReactGA.event({
      category,
      action,
      label
    });
  };

  const changeTheme = (theme) => {
    sendGAEvent('change_theme', 'click', theme)
    setShowThemeOptions(false);
    setTheme(theme);
  };

  const changeSkillHandler = (isSkillOpen, element) => {
    sendGAEvent(`show_skill`, 'click', `show_skill_${element}`)
    setSkillOpen(isSkillOpen);
  };

  useEffect(() => {
    sendGAEvent('language','initial_load',userLang);
  }, [userLang]);
  
  return (
    <>
    <div className={"app-layout " + dynamicCSS({app: theme})}>
      {/* { translations && <Menu translations={translations} theme={theme} /> } */}
      <Header theme={theme}>
        Miguel Aguilar
      </Header>
      {translations?.presentationSequence && 
        <Presentation theme={theme} langId={langId}/>
      }
      {/* {translations?.phrase &&
        <h1 className={`phrase ${dynamicCSS({phrase: theme})}`}>"{translations.phrase}"</h1>
      } */}
      {translations?.skills && 
        <>
          <div className="profile-img-container">
            <img src="yo.jpg" className={"profile-img-layout " + dynamicCSS({profile_img: theme}) + " "  + dynamicCSS({profile_img_open: isSkillOpen })} alt="profile" 
            onClick={() => changeSkillHandler(!isSkillOpen, 'img')}/>
            <FontAwesomeIcon icon={isSkillOpen ? faChevronUp : faChevronDown} size="xl" className={`collapseIcon ${dynamicCSS({collapseIcon: theme})} ${dynamicCSS({collapseIconOpen: isSkillOpen})}`} 
            onClick={() => changeSkillHandler(!isSkillOpen, 'icon')} fade />
          </div>
          <Skill skills={translations?.skills} theme={theme} experienceText={translations?.experience} yearsText={translations?.years} levelText={translations?.level} isSkillOpen={isSkillOpen}/>
        </>
      }
      
      {translations?.about_me && 
        <section>
          
            <ScrollAnimation animateIn="animate__fadeInLeft" scrollableParentSelector=".app-layout" offset={0} animateOnce={true} initiallyVisible={true}>
              <Card 
                title={translations?.about_me}
                description={translations?.about_me_description} 
                theme={theme} />
            </ScrollAnimation>
            
            <ScrollAnimation animateIn="animate__fadeInRight" scrollableParentSelector=".app-layout" offset={100} animateOnce={true}>
              <Card 
                title={translations?.companies_title}
                theme={theme}>
                <a className={`company-container ${dynamicCSS({inset_img: theme})}`} onClick={() => {sendGAEvent('company_link','click','paypal')}} href="https://www.paypal.com/" target="_blank"><img className="company-logo" src="paypal.jpg" alt="PayPal" /></a>
                <a className={`company-container ${dynamicCSS({inset_img: theme})}`} onClick={() => {sendGAEvent('company_link','click','shutterstock')}} href="https://www.shutterstock.com/" target="_blank"><img className="company-logo" src="shutterstock.jpg" alt="Shutterstock" /></a>
                <a className={`company-container ${dynamicCSS({inset_img: theme})}`} onClick={() => {sendGAEvent('company_link','click','benchmarkdigital')}} href="https://benchmarkdigital.com/" target="_blank"><img className="company-logo" src="gensuite.jpg" alt="Benchmark Gensuite" /></a>
                <a className={`company-container ${dynamicCSS({inset_img: theme})}`} onClick={() => {sendGAEvent('company_link','click','upa')}} href="https://upa.edu.mx/" target="_blank"><img className="company-logo" src="upa.jpg" alt="Universidad Politécnica de Aguascalientes" /></a>
                <a className={`company-container ${dynamicCSS({inset_img: theme})}`} onClick={() => {sendGAEvent('company_link','click','bairesdev')}} href="https://www.bairesdev.com/" target="_blank"><img className="company-logo" src="bairesdev.jpg" alt="BairesDev" /></a>
                <a className={`company-container ${dynamicCSS({inset_img: theme})}`} onClick={() => {sendGAEvent('company_link','click','lokavant')}} href="https://www.lokavant.com/" target="_blank"><img className="company-logo" src="lokavant.jpg" alt="Lokavant" /></a>
                <a className={`company-container ${dynamicCSS({inset_img: theme})}`} onClick={() => {sendGAEvent('company_link','click','designa')}} href="https://designa.mx/" target="_blank"><img className="company-logo" src="designa.jpg" alt="Designa" /></a>
                <a className={`company-container ${dynamicCSS({inset_img: theme})}`} onClick={() => {sendGAEvent('company_link','click','max4technologies')}} href="https://max4technologies.com/" target="_blank"><img className="company-logo" src="max4.jpg" alt="Max4 Technologies" /></a>
              </Card>
            </ScrollAnimation>

            <ScrollAnimation animateIn="animate__fadeInLeft" scrollableParentSelector=".app-layout" offset={50} animateOnce={true}>
              <Card 
                title={translations?.follow_me}
                theme={theme} >
                <SocialLink theme={theme} socialName="linkedin"/>
                <SocialLink theme={theme} socialName="twitter"/>
                <SocialLink theme={theme} socialName="instagram"/>
                <SocialLink theme={theme} socialName="tiktok"/>
              </Card>
            </ScrollAnimation>

            <ScrollAnimation animateIn="animate__fadeInRight" scrollableParentSelector=".app-layout" offset={50} animateOnce={true}>
              <Card 
                title={translations?.phrase}
                theme={theme} >
                  <p>
                    <a className="link" href="https://wa.me/524492657438?text=¡Hola%21+Acabo+de+ver+tu+página+web+y+me+encantó.+Me+gustaría+tener+una+así+para+mi.+¿Me+podrías+informar+qué+planes+tienes+disponibles+y+sus+precios%3F">{translations?.contact_whatsapp}</a><br/>
                    {translations?.or}<br/>
                    <a className="link" href="mailto: ing.maac@hotmail.com">{translations?.contact_email}</a>
                  </p>
              </Card>
            </ScrollAnimation>
        </section>
      }
      
      <span className='icons-container'>
        {showThemeOptions && 
          (<>
            <span className='change-theme-button text-white' onClick={() => changeTheme(themes.NEOBRUTALISM)}>Neo Brutalism</span>
            <span className='change-theme-button text-white' onClick={() => changeTheme(themes.GLASSMORPHISM)}>Glassmorphism</span>
            <span className='change-theme-button text-white' onClick={() => changeTheme(themes.NEUROMORPHISM)}>Neuromorphism</span>
          </>)
        }
        <span className='change-theme-icon text-white' 
          onClick={() => setShowThemeOptions(!showThemeOptions)}>
          <FontAwesomeIcon icon={faBrush} size="lg" beatFade={isModalVisible}/>
        </span>
      </span>

      { isModalVisible && translations?.theme_instructions &&
        <Modal
          title={translations?.theme_modal_title}
          description=""
          btnText="Ok"
          btnHandler={() => setModal(false)}
          theme={theme} >
          <p 
            dangerouslySetInnerHTML={
            getTextWithLink(translations?.theme_instructions,
            "<a href='https://hype4.academy/articles/design/neubrutalism-is-taking-over-web' target='_blank'>NEO-BRUTALISM</a>")}
          />
        </Modal>
      }
      
      <LanguageSelector langId={langId} setLanguage={setLanguage}/>
      
    </div>
    { theme === themes.GLASSMORPHISM &&
    <div className="gradientBg">
      <div className="gradientsContainer">
        <div className="g1"></div>
        <div className="g2"></div>
        <div className="g3"></div>
        <div className="g4"></div>
        <div className="g5"></div>
        <div className="interactive"></div>
      </div>
    </div>
    }
  </>
  );
}

export default App;
