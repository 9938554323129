import React from 'react';
import dynamicCSS from './cssInJs/css';

const Skill = ({theme, skills, levelText, experienceText, yearsText, isSkillOpen}) => {
  return (
    <div className={'skills-section' + ' ' + dynamicCSS({isSkillOpen: isSkillOpen})}>
        {
          skills?.map((item) => {
            const classLevel = item.years <= 1 ? 'basic' : item.years > 1 && item.years <= 3 ? 'intermediate' : item.years > 3 ? 'advanced' : '';
            return (
              <span key={item.id} className={`${["circle-" + classLevel]} circle ${dynamicCSS({skill: theme})} ${dynamicCSS({circle: classLevel})}`}>
                <span className={'inner-circle ' + dynamicCSS({'inner-circle': classLevel})}>
                  <span className={"progressbar " + dynamicCSS({progressbar: classLevel})}></span>
                  <span className='flip-coin-inner'>
                    <span className='flip-coin-front'>{item.name}</span>
                    <span className='flip-coin-back'>
                      {levelText}: {item.level}<br/>
                      {experienceText} ({yearsText}): {item.years}
                    </span>
                  </span>
                  
                </span>
              </span>
            );
          })
        }
    </div>
  )
};

export default Skill;